import React from "react";
import { parse, stringify } from "qs";
import config from "config";
import crypto from "crypto-api/src/crypto-api.mjs";
import { message, notification } from "antd";
import secureStorage from "./secureStorage";
import { default as reactHtmlParser } from "html-react-parser";
import Interweave from "interweave";
import html from "is-html";
export * from "./secureStorage";

message.config({
  top: 70,
  duration: 2,
  maxCount: 3,
  rtl: true,
});

export const parseQueryParams = (str) =>
  parse(str, { ignoreQueryPrefix: true });

export const checkChallengeString = (challenge, string) => {
  if (!challenge || !string) return false;

  const hasher = crypto.getHasher("md5");
  const hash = crypto.hmac(config.secret, string, hasher);

  // console.log({ challenge, string, hash });
  return challenge === hash;
};

export const titleCase = (str) => {
  if (!str) return "";
  return str.toLowerCase().replace(/(^|\s)\S/g, (t) => t.toUpperCase());
};

export const getLanguages = () => {
  const langs = [
    {
      label: "English",
      value: "en",
    },
    {
      label: "Marathi",
      value: "mr",
    },
    {
      label: "Gujarati",
      value: "gu",
    },
    {
      label: "Hindi",
      value: "hi",
    },
    {
      label: "Tamil",
      value: "ta",
    },
    {
      label: "Telugu",
      value: "te",
    },
  ];

  return langs;
};

export const parseLanguage = (lang = "en") => {
  const languages = getLanguages();
  return languages.find((l) => l.value === lang)?.label || "English";
};

export const showSuccessMessage = (msg, duration = 3) => {
  return message.success(msg, duration);
};

export const showErrorMessage = (msg, duration = 5) => {
  return message.error(msg, duration);
};

export const showWarningMessage = (msg, duration = 3) => {
  return message.warning(msg, duration);
};

export const showNoInternetAlert = () => {
  showWarningMessage("You are offline", 0.5);
};

export const showInternetAlert = () => {
  showSuccessMessage("You are connected to Internet", 0.5);
};

export const showNotification = (
  msg,
  desc,
  duration = 3,
  placement = "topLeft"
) => {
  return notification.info({
    message: `${msg}`,
    description: `${desc}`,
    placement,
    duration,
  });
};

export const getUTFDecodedText = (text, isHtml = true) => {
  let htmlCheck = html(text);
  if (text && text !== undefined && text != "") {
    var unicodeConverter = /\\u([\d\w]{4})/gi;
    var text = text.replace(unicodeConverter, function (match, grp) {
      return String.fromCharCode(parseInt(grp, 16));

      //
    });
    let htmlCheck = html(text);
    //console.log("Return", text);
    if (htmlCheck) {
      // return <div dangerouslySetInnerHTML={{ __html: unescape(text) }}></div>;
      return (
        <Interweave
          content={text
            .replace(/\\n/g, " ")
            .replace(/\\r/g, " ")
            .replace(/\\t/g, " ")
            .replace(/&colon/g, ":")}
        />
      );
    } else {
      return text
        .replace(/\\n/g, " ")
        .replace(/\\r/g, " ")
        .replace(/\\t/g, " ")
        .replace(/&colon/g, ":");
    }
  }
  if (htmlCheck) {
    return <Interweave content={text} />;
  } else {
    return text;
  }
};

export const htmlParser = (text = "") => {
  return;
  // reactHtmlParser(
  getUTFDecodedText(text, false);
  // );
};

export const getEncodedJWTToken = (payload) => {
  var jwt = require("jsonwebtoken");
  var token = jwt.sign(payload, config.jwtSecretKey);
  return token;
};

export const handleExternalLinks = (link, type, props) => {
  props.history.push("/displayLink", { url: link, type: type });
};

export function selectLanguageConvert(language) {
  return parseLanguage(language);
  // switch (language) {
  //   case "en":
  //     return "English";
  //     break;
  //   case "mr":
  //     return "Marathi";
  //     break;
  //   case "gu":
  //     return "Gujarathi";
  //     break;
  //   case "hi":
  //     return "Hindi";
  //     break;
  //   case "ta":
  //     return "Tamil";
  //     break;
  //   case "te":
  //     return "Telgu";
  //     break;
  //   default:
  //     return language;
  // }
}

export const shareUrlOnSocialMedia = (url) => {};

export const redirectTo = (link) => {
  if (!link) return;
  window.location.href = link;
};

export const objectToQS = (val) => {
  if (!val) return "";
  return stringify(val);
};

export const openInNewTab = (link) => {
  if (!link) return "";
  return window.open(link, "_blank");
};

export function selectGradeConvert(number) {
  switch (number) {
    case "1":
      return "I";
    case "2":
      return "II";
    case "3":
      return "III";
    case "4":
      return "IV";
    case "5":
      return "V";
    case "6":
      return "VI";
    case "7":
      return "VII";
    case "8":
      return "VIII";
    case "9":
      return "IX";
    case "10":
      return "X";
    case "11":
      return "XI";
    case "12":
      return "XII";
    default:
      return number;
  }
}

export function getQuizTheme(type) {
  let themeClass = "challenge-theme-1";
  switch (type) {
    case "1":
      themeClass = "challenge-theme-1";
      break;
    case "2":
      themeClass = "challenge-theme-2";
      break;
    case "3":
      themeClass = "challenge-theme-3";
      break;
    case "4":
      themeClass = "challenge-theme-4";
      break;
    default:
      themeClass = "challenge-theme-1";
      break;
  }
  return themeClass;
}

export function getIslandThemeClass(type) {
  let themeClass = "island-theme-1";
  let themeModalClass = "island-modal-theme-" + type;
  switch (type) {
    case "1":
      themeClass = "island-theme-1";
      break;
    case "2":
      themeClass = "island-theme-2";
      break;
    case "3":
      themeClass = "island-theme-3";
      break;
    case "4":
      themeClass = "island-theme-4";
      break;
    default:
      themeClass = "island-theme-1";
      themeModalClass = "island-modal-theme-1";
      break;
  }
  return { themeClass: themeClass, themeModalClass: themeModalClass };
}
export function getThemeClass(type) {
  let themeClass = "challenge-theme-1 challenge-content";
  switch (type) {
    case "1":
      themeClass = "challenge-theme-1 challenge-content";
      break;
    case "2":
      themeClass = "challenge-theme-2 challenge-content";
      break;
    case "3":
      themeClass = "challenge-theme-3 challenge-content";
      break;
    case "4":
      themeClass = "challenge-theme-4 challenge-content";
      break;
    default:
      themeClass = "challenge-theme-1 challenge-content";
      break;
  }
  return themeClass;
}

export default {
  parseQueryParams,
  checkChallengeString,
  titleCase,
  getLanguages,
  parseLanguage,
  showSuccessMessage,
  showErrorMessage,
  showWarningMessage,
  showNotification,
  showInternetAlert,
  getUTFDecodedText,
  secureStorage,
  htmlParser,
  openInNewTab,
  objectToQS,
  redirectTo,
  getQuizTheme,
  getThemeClass,
  getIslandThemeClass,
};
