import { SAVE, REMOVE } from "./types";

const INITIAL_STATE = {
  dataContent: null,
};

export default function (state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case SAVE: {
      return {
        ...state,
        dataContent: payload,
      };
    }
    case REMOVE: {
      return {
        ...state,
        ...INITIAL_STATE,
      };
    }
    default:
      return state;
  }
}
