import './polyfill'

import React from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import * as Sentry from "@sentry/react";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import * as serviceWorker from "./serviceWorker";
import { registerServiceWorker } from "./register-sw-fcm";
import App from "./App";
import i18n from "./i18n";
import config from "config";
import "./assets/new_design/css/index.css";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";

console.log("Environment", process.env.NODE_ENV);

if (process.env.NODE_ENV === "production") {
  Sentry.init({ dsn: config.sentryDSN, environment: process.env.NODE_ENV });
  disableReactDevTools();
}

// registerServiceWorker();

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
