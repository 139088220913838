import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, withRouter, useHistory } from "react-router-dom";
import { Provider } from "react-redux";
import Routes, { newHistory } from "./routes";
import store from "./store";
import config from "config";
import { messaging } from "./services/firebaseServices/firebaseAnalytics";
import { compose, lifecycle, withHandlers, withState } from "recompose";
import { isSafari } from "react-device-detect";
import { useClearCache } from "react-clear-cache";
import { showNoInternetAlert, showInternetAlert } from "./services/utility";
import { Modal, Button, ModalFooter, ModalHeader, ModalBody } from "reactstrap";

var NewshowExitModal = false;
let deferredPrompt = null;

// const registerPushListener = (pushNotification) => {
//   if (navigator.serviceWorker)
//     navigator.serviceWorker.addEventListener("message", (event) => {
//       console.log(`The service worker sent me a message: ${event.data}`);
//       NewshowExitModal = true;
//       console.log("NewShowExitMOdals", NewshowExitModal);
//       pushNotification(event.data.firebaseMessaging.payload.notification);
//     });
// };

//export const socket = openSocket("https://socketserverquiz.herokuapp.com");

//export const socket = openSocket("http://13.232.230.14:4000");

//export const socket = io();
//export const socket = io();
//
//export const socket = require("socket.io");

const App = ({ token, notifications }) => {
  const { isLatestVersion } = useClearCache({
    duration: config.cacheClearDuration,
  });
  // console.log({ isLatestVersion });

  const [showExitModal, setShowExitModal] = useState(NewshowExitModal);
  useEffect(() => {
    setShowExitModal(NewshowExitModal);
  }, [NewshowExitModal]);
  const toggle = () => {
    NewshowExitModal = !NewshowExitModal;
    setShowExitModal(NewshowExitModal);
  };
  const reDirect = (url) => {
    window.location.assign(url);
  };

  var renderNotification = (notification, i) => {
    return (
      <Modal isOpen={showExitModal}>
        <ModalHeader closeButton>
          {/* <ModalTitle className="modal-title text-orange"> */}
          STEPapp
          {/* </ModalTitle> */}
        </ModalHeader>
        <ModalBody>{notification.body}</ModalBody>
        <ModalFooter>
          <Button
            variant="primary"
            onClick={(e) => {
              toggle();
              reDirect(notification.click_action);
            }}
            className="modal-button"
          >
            Yes
          </Button>
          <Button
            variant="secondary"
            onClick={toggle}
            className="modal-button-grey"
          >
            No
          </Button>
        </ModalFooter>
      </Modal>
    );
  };
  // console.log("FCM ", token);
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes />
        <div>{notifications.map(renderNotification)}</div>
      </BrowserRouter>
    </Provider>
  );
};

export default compose(
  withState("token", "setToken", ""),
  withState("notifications", "setNotifications", []),
  withHandlers({
    pushNotification: ({ setNotifications, notifications }) => (
      newNotification
    ) => setNotifications(notifications.concat(newNotification)),
  }),
  lifecycle({
    async componentDidMount() {
      window.addEventListener("DOMContentLoaded", () => {
        let displayMode = "browser-tab";
        if (navigator.standalone) {
          displayMode = "standalone-ios";
        } else if (window.matchMedia("(display-mode: standalone)").matches) {
          displayMode = "standalone";
        }
        window.localStorage.setItem("launchmode", displayMode);

        // Log launch display mode to analytics
        console.log("DISPLAY_MODE_LAUNCH:", displayMode);
      });

      window.addEventListener("beforeinstallprompt", (e) => {
        // Prevent the mini-infobar from appearing on mobile
        e.preventDefault();

        // Stash the event so it can be triggered later.
        window.deferredPrompt = e;
        console.log("Registerd event");
        // Update UI notify the user they can install the PWA
        window.localStorage.setItem("pwanotinstalled", "true");
        //this.showInstallPromotion();
      });
      window.addEventListener("appinstalled", (evt) => {
        // Log install to analytics
        console.log("INSTALL: Success");
        window.localStorage.setItem("pwanotinstalled", "false");
      });
      window.addEventListener("online", () => {
        console.log("Connected Internet");
        showInternetAlert();
      });
      window.addEventListener("offline", () => {
        showNoInternetAlert();
        console.log("No Internet");
      });
      if (!isSafari) {
        const { pushNotification, setToken } = this.props;
        // if('serviceWorker' in navigator) {
        //   navigator.serviceWorker.register('../public/firebase-messaging-sw')
        // .then(function(registration) {
        //  console.log("Service Worker Registered");
        // messaging.useServiceWorker(registration);
        //   });
        //   }
        // if (messaging)
        //   messaging
        //     .requestPermission()
        //     .then(async function () {
        //       // const token = await messaging.getToken();
        //       // setToken(token);
        //       messaging.getToken().then((token) => {
        //         setToken(token);
        //         if (token) {
        //           setToken(token);
        //           window.localStorage.setItem("firebaseToken", token);
        //           // sendTokenToServer(token);
        //           // updateUIForPushEnabled(token);
        //         } else {
        //           // Show permission request.
        //           console.log(
        //             "No Instance ID token available. Request permission to generate one."
        //           );
        //           // Show permission UI.
        //           // updateUIForPushPermissionRequired();
        //           // setTokenSentToServer(false);
        //         }
        //       });
        //     })
        //     .catch(function (err) {
        //       console.log("Unable to get permission to notify.", err);
        //     });

        // registerPushListener(pushNotification);
      }
    },
  })
)(App);
