import store from 'store';
import { parseQueryParams } from "services/utility";
import { refreshUser as refreshUserAction } from "store/user/actions";
import { setConfig } from 'store/config/actions';
import { isLoggedInSelector } from 'store/auth/selectors';

const routeGuard = (to, from, next) => {
    // console.log('route guard');
    const state = store.getState();
    const isLoggedIn = isLoggedInSelector(state);
    const canAccessLiveClasses = state.user?.school?.activatedProducts?.includes('liveclasses');

    const { refreshUser = false } = parseQueryParams(from.location.search);
    store.dispatch(setConfig({ enableChangeLanguage: false }));

    if (refreshUser) {
        store.dispatch(refreshUserAction());
    }

    if (to.meta.public) return next();

    // If user is not loggedin and access private route
    if (to.meta.auth && !isLoggedIn) {
        store.dispatch(setConfig({ redirectUrl: to.location.pathname }));
        return next.redirect('/');
    }
    // If user is loggedin and access the public route
    else if (to.meta.guest && isLoggedIn) {
        return next.redirect('/selectsubject');
    }

    if (to.location.pathname === '/live-classes' && !canAccessLiveClasses) {
        return next.redirect('/selectsubject');
    }
    // console.log('route guard - access allowed');
    return next();
};

export default routeGuard;