import db from "../../services/DBStorage.js";
import api from "../../services/api.js";
import config from "../../config.json";
import { secureStorage, getEncodedJWTToken } from "services/utility";
import store from "store";
import { save as saveUser } from "store/user/actions";

export async function getUserProfileData() {
  var { user } = store.getState();

  if (user) {
    return user;
  }
  return null;
}

/*
export function isUseLoggedIn() {
  let isUserLoggedIn = true;
  if (secureStorage.getItem("userLoggedIn")) {
    isUserLoggedIn = true;
  } else {
    isUserLoggedIn = false;
  }
  return isUserLoggedIn;
}
export function setUserLoggedIn() {
  secureStorage.setItem("userLoggedIn", "true");
}
*/
export async function saveUserDataToLocalStorage(data) {
  if (data) {
    store.dispatch(saveUser({ user: data }));
    // await db.userData.clear();
    // await db.userData.put({ ...data }).then((user) => {});
  }
}

export async function getSelectedBoardAndGradeFromLocal() {
  //let userData = getUserProfileData();
  const { user } = store.getState();
  // const userProfileData = user;
  // console.log("userProfileData", userProfileData);
  if (user) {
    //let userData = userProfileData;
    let selectedBoard = user.board_name;
    let selectedGrade = user.grade_name;
    let selectedLanguage = user.lang_pref;
    let selectedSchool = user.school_id;
    let userId = user.id;

    if (
      selectedBoard &&
      selectedBoard.length > 0 &&
      selectedGrade &&
      selectedGrade.length > 0 &&
      selectedLanguage &&
      selectedLanguage.length > 0 &&
      selectedSchool &&
      selectedSchool > 0 &&
      userId &&
      userId.length > 0
    ) {
      return {
        selectedBoard: selectedBoard,
        selectedGrade: selectedGrade,
        selectedLanguage: selectedLanguage,
        selectedSchool: selectedSchool,
        userId: userId,
      };
    }
  }
  //return null;
}

export async function setUserLoggedOut() {
  await db.lrsData.clear();
  await db.subjectContent.clear();
  await db.lrsSync.clear();
  await db.feedRailData.clear();
  await db.bookmarks.clear();

  // await db.close();
  secureStorage.removeItem("userLoggedIn");
}
export async function setUserProfile(userdata) {
  // console.log("setProfile", userdata);
  const inputData = {
    user_mobile: userdata.user_mobile,
    country_code: userdata.country_code,
    school_id: userdata.school_id,
    board_name: userdata.board_name,
    lang_pref: userdata.lang_pref,
    grade_name: userdata.grade_name,
    first_name: userdata.first_name,
    last_name: userdata.last_name,
    user_email: userdata.user_email,
  };

  let authToken = getEncodedJWTToken(inputData);
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  const { data: userProfileData } = await api.post(
    `${config.apiEndPoint}/tamilnadu/user`,
    inputData,
    {
      headers: headers,
    }
  );
  window.profileData = userProfileData;
  await saveUserDataToLocalStorage(userProfileData);
  return userProfileData;
}

export async function getUserProfile(mobileNo, schoolId, countryCode) {
  const inputData = {
    user_mobile: mobileNo,
    country_code: countryCode,
    school_id: schoolId,
  };
  let authToken = getEncodedJWTToken(inputData);
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  const { data: userProfileData } = await api.post(
    `${config.apiEndPoint}/tamilnadu/user`,
    inputData,
    {
      headers: headers,
    }
  );
  return userProfileData;
  // console.log("userdata", userProfileData);
}

export async function getCompletedTopicsFromUserData(subjectslug, chapterslug) {
  const { user } = store.getState();
  //  const userProfileData = user;
  // const userProfileData = await db.userData.toArray();
  if (user) {
    var subjects = await user.subjectDetails;
  }

  if (subjects && subjects.length > 0) {
    let filteredSubjects = subjects.filter(
      (subject) => subject.slug === subjectslug
    );
    if (filteredSubjects && filteredSubjects.length > 0) {
      let filteredChapter = filteredSubjects[0].chapter.filter(
        (chapter) => chapter.slug === chapterslug
      );
      if (filteredChapter && filteredChapter.length > 0) {
        return filteredChapter[0].topic;
        // let filteredTopic = filteredChapter[0].topic.filter(
        //   (topic) => topic.slug === topicslug
        // );
        // if (filteredTopic && filteredTopic.length > 0) {
        //   return filteredTopic[0];
        // }
      }
    }
  }
  return null;
  //console.log("userProfileData", userProfileData);
}

export async function addtoBookmark(url, concept, subject) {
  await db.bookmarks.put({
    url: url,
    bookmarkName: concept.name,
    subjectName: subject,
    concept_id: concept.concept_id,
  });
}
export async function removeFromBookmark(concept_id) {
  db.bookmarks
    .where("concept_id")
    .equals(concept_id)
    .delete()
    .then(function (deleteCount) {
      // console.log("Deleted " + deleteCount + " objects");
    });
}
export async function getBookmarks(url) {
  let arrBookmarks = [];
  if (url.length > 0) {
    //let arrBookmarks = await db.bookmarks.where("url").equals(url).toArray();
    arrBookmarks = await db.bookmarks.where("url").startsWithIgnoreCase(url)
      .toArray;
  } else {
    arrBookmarks = await db.bookmarks.toArray();
  }

  return arrBookmarks;
}

export async function getSelectedTopicFromUserData(
  subjectslug,
  chapterslug,
  topicslug
) {
  let completedTopics = await getCompletedTopicsFromUserData(
    subjectslug,
    chapterslug
  );
  if (completedTopics) {
    let filteredTopic = completedTopics.filter(
      (topic) => topic.slug === topicslug
    );
    if (filteredTopic && filteredTopic.length > 0) {
      return filteredTopic[0];
    }
  }
  return null;
}
/*
export async function clearUser() {
  db.userData.clear().then((f) => f);
}
*/
