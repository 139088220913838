import { combineReducers } from "redux";

import auth from "./auth";
import user from "./user";
import config from "./config";
import dataContent from "./DataContent";
import playersReducer from "../Socket/reducers/players";
import gameReducer from "../Socket/reducers/game";
import clientTypeReducer from "../Socket/reducers/clientType";

const rootReducer = combineReducers({
  auth,
  user,
  config,
  dataContent,
  players: playersReducer,
  type: clientTypeReducer,
  game: gameReducer,
});

export default rootReducer;
