import Dexie from "dexie";
import encrypt from "dexie-encrypted";
// import config from "config";
import nacl from "tweetnacl";
const keyPair = nacl.sign.keyPair.fromSeed(new Uint8Array(32));

const db = new Dexie("StepAppDb");

db.version(1).stores({
  schoolList: "school_name",
  gradeData: "grade",
  selectedSchool: "id",
  lrsData: "++id",
  preSelectedSchool: "id",
  userData: "id",
  subjectContent: "user",
});
db.version(2).stores({
  schoolList: "school_name",
  gradeData: "grade",
  selectedSchool: "id",
  lrsData: "++id",
  preSelectedSchool: "id",
  userData: "id",
  subjectContent: "user",
});
db.version(3).stores({
  schoolList: "school_name",
  gradeData: "grade",
  selectedSchool: "id",
  lrsData: "++id",
  preSelectedSchool: "id",
  userData: "id",
  subjectContent: "user",
  lrsSync: "user",
});
db.version(4).stores({
  schoolList: "school_name",
  gradeData: "grade",
  selectedSchool: "id",
  lrsData: "++id",
  preSelectedSchool: "id",
  userData: "id",
  subjectContent: "user",
  lrsSync: "user",
});
db.version(5).stores({
  schoolList: "school_name",
  gradeData: "grade",
  selectedSchool: "id",
  lrsData: "++id",
  preSelectedSchool: "id",
  userData: "id",
  subjectContent: "user",
  lrsSync: "user",
  feedRailData: "user",
});
db.version(6).stores({
  schoolList: "school_name",
  gradeData: "grade",
  selectedSchool: "id",
  lrsData: "++id",
  preSelectedSchool: "id",
  userData: "id",
  subjectContent: "user",
  lrsSync: "user",
  feedRailData: "user",
});
db.version(7).stores({
  schoolList: "school_name",
  gradeData: "grade",
  selectedSchool: "id",
  lrsData: "++id",
  preSelectedSchool: "id",
  userData: "id",
  subjectContent: "user",
  lrsSync: "user",
  feedRailData: "user",
});
db.version(8).stores({
  schoolList: "school_name",
  gradeData: "grade",
  selectedSchool: "id",
  lrsData: "++id",
  preSelectedSchool: "id",
  userData: "id",
  subjectContent: "user",
  lrsSync:
    "context.timestamp,learn_object_type,[learn_object.subjectSlug+learn_object_id],learn_object.chapterSlug",
  feedRailData: "user",
});
db.version(9).stores({
  schoolList: "school_name",
  gradeData: "grade",
  selectedSchool: "id",
  lrsData: "++id",
  preSelectedSchool: "id",
  userData: "id",
  subjectContent: "user",
  lrsSync:
    "context.timestamp,learn_object_type,[learn_object.subjectSlug+learn_object_id],learn_object.chapterSlug",
  feedRailData: "asset_type",
});
db.version(10).stores({
  schoolList: "school_name",
  gradeData: "grade",
  selectedSchool: "id",
  lrsData: "++id",
  preSelectedSchool: "id",
  userData: "id",
  subjectContent: "user",
  lrsSync:
    "context.timestamp,learn_object_type,[learn_object.subjectSlug+learn_object_id],learn_object.chapterSlug",
  feedRailData: "asset_type",
  bookmarks: "url",
});
db.version(11).stores({
  schoolList: "school_name",
  gradeData: "grade",
  selectedSchool: "id",
  lrsData: "++id",
  preSelectedSchool: "id",
  userData: "id",
  subjectContent: "user",
  lrsSync:
    "context.timestamp,learn_object_type,[learn_object.subjectSlug+learn_object_id],learn_object.chapterSlug",
  feedRailData: "asset_type",
  bookmarks: "url",
});
db.version(12).stores({
  schoolList: "school_name",
  gradeData: "grade",
  selectedSchool: "id",
  lrsData: "++id",
  preSelectedSchool: "id",
  userData: "id",
  subjectContent: "user",
  lrsSync:
    "context.timestamp,learn_object_type,[learn_object.subjectSlug+learn_object_id],learn_object.chapterSlug",
  feedRailData: "asset_type",
  bookmarks: "url,bookmarkName,subjectName,concept_id",
});
db.version(13).stores({
  schoolList: "school_name",
  gradeData: "grade",
  selectedSchool: "id",
  lrsData: "++id",
  preSelectedSchool: "id",
  userData: "id",
  subjectContent: "user",
  lrsSync:
    "context.timestamp,learn_object_type,[learn_object.subjectSlug+learn_object_id],learn_object.chapterSlug",
  feedRailData: "asset_type",
  bookmarks: "url,bookmarkName,subjectName,concept_id",
});
db.version(14).stores({
  schoolList: "school_name",
  gradeData: "grade",
  selectedSchool: "id",
  lrsData: "++id",
  preSelectedSchool: "id",
  userData: "id",
  subjectContent: "user",
  lrsSync:
    "context.timestamp,learn_object_type,[learn_object.subjectSlug+learn_object_id],learn_object.chapterSlug",
  feedRailData: "asset_type",
  bookmarks: "url,bookmarkName,subjectName,concept_id",
  conceptBookmark: "concept_id,bookmarkName,subjectName,url",
  rating: "topicslug,rating",
});
db.version(15).stores({
  schoolList: "school_name",
  gradeData: "grade",
  selectedSchool: "id",
  lrsData: "++id",
  preSelectedSchool: "id",
  userData: "id",
  subjectContent: "user",
  lrsSync:
    "context.timestamp,learn_object_type,[learn_object.subjectSlug+learn_object_id],learn_object.chapterSlug",
  feedRailData: "asset_type",
  bookmarks: "url,bookmarkName,subjectName,concept_id",
  conceptBookmark: "concept_id,bookmarkName,subjectName,url",
  rating: "topicslug,rating",
});
// db.version(10).stores({
//   schoolList: "school_name",
//   gradeData: "grade",
//   selectedSchool: "id",
//   lrsData: "++id",
//   preSelectedSchool: "id",
//   userData: "id",
//   subjectContent: "user",
//   lrsSync:
//     "context.timestamp,learn_object_type,[learn_object.subjectSlug+learn_object_id],learn_object.chapterSlug",
//   feedRailData: "asset_type",
//   lrsSyncData1:
//     "context.timestamp,learn_object_type,learn_object.subjectSlug,learn_object_id,learn_object.chapterSlug",
// });
encrypt(db, keyPair.publicKey, {
  schoolList: encrypt.DATA,
  gradeData: encrypt.DATA,
  selectedSchool: encrypt.DATA,
  lrsData: encrypt.DATA,
  preSelectedSchool: encrypt.DATA,
  userData: encrypt.DATA,
  subjectContent: encrypt.DATA,
  feedRailData: encrypt.NON_INDEXED_FIELDS,
  //bookmarks: encrypt.NON_INDEXED_FIELDS,
});

export default db;
