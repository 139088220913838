import store from 'store';
import api from "services/api";

function getPageInfo({ meta, match }) {
    let grade, board;
    switch (meta.name) {
        case 'subject':
            return {
                grade: '',
                board: '',
                productType: 'subject',
                entityId: match.params.subjectslug,
            };
        case 'chapter':
            [, grade, board] = match.params.subjectslug.split('-');
            return {
                grade,
                board,
                productType: 'chapter',
                entityId: match.params.chapterslug,
            };
        case 'topic':
            [, grade, board] = match.params.subjectslug.split('-');
            return {
                grade,
                board,
                productType: 'topic',
                entityId: match.params.topicslug,
            };
        default:
            [, grade, board] = match.params.subjectslug.split('-');
            return {
                grade,
                board,
                productType: 'topic',
                entityId: match.params.topicslug,
            };
    }
}

function getPayload(user, route) {
    const page = getPageInfo(route);
    return {
        userId: user?.id,
        boardName: page?.board,
        gradeName: page?.grade,
        // boardName: user && user.board_name,
        // gradeName: user && user.grade_name,
        schoolId: user?.school_id,
        productType: page?.productType,
        entityId: page?.entityId,
    };
}

function shouldCheckEntitlement(user, route) {
    const { grade: routeGrade, board: routeBoard } = getPageInfo(route);
    return user?.grade_name != routeGrade || user?.board_name?.toLowerCase() != routeBoard?.toLowerCase();
}

const contentGuard = async (to, from, next) => {
    try {
        const { user } = store.getState();
        // console.log('content guard', { to, from });

        if (shouldCheckEntitlement(user, to)) {
            const payload = getPayload(user, to);
            const { status } = await api.getApi('getentitlement', payload);

            if (!status) return next.redirect('/plans');
        }

        // console.log('content guard - access allowed');
        return next();
    } catch (err) {
        // console.log('content guard - access denied', err);
        return next('/plans');
    }
};

export default contentGuard;
