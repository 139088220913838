import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";

//import translationEng from "../src/locales/en/translation.json";
//import translationHin from "../src/locales/hi/translation.json";
// import translationGer from "../locales/ger/translation.json";
// import translationFre from "../locales/fre/translation.json";
// import translationHin from "../locales/hin/translation.json";
// import translationJap from "../locales/jap/translation.json";
i18n
  .use(XHR)
  .use(LanguageDetector)
  .init({
    debug: true,
    // lng: localStorage.getItem("I18N_LANGUAGE") || "hi",
    fallbackLng: "en", // use en if detected lng is not available
    detection: {
      order: ["localStorage", "navigator"],
      lookupQuerystring: "lng",
      lookupLocalStorage: "I18N_LANGUAGE",
      caches: ["localStorage"],
    },
    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      wait: false,
    },
    // react: {
    //   bindI18n: "languageChanged",
    //   bindI18nStore: "",
    //   transEmptyNodeValue: "",
    //   transSupportBasicHtmlNodes: true,
    //   transKeepBasicHtmlNodesFor: ["br", "strong", "i"],
    //   useSuspense: true,
    // },
    // resources: {
    //   en: {
    //     translations: translationEng,
    //   },
    // ger: {
    //   translations: translationGer,
    // },
    // fre: {
    //   translations: translationFre,
    // },
    // hin: {
    //   translations: translationHin,
    // },
    // jap: {
    //   translations: translationJap,
    // },
    //},
    // // have a common namespace used around the full app
    // ns: [translations],
    // defaultNS: translations,
  });

export default i18n;
