import { createStore, applyMiddleware, compose } from "redux";
import logger from "redux-logger";
import freeze from "redux-freeze";
import thunk from "redux-thunk";
import promise from "redux-promise-middleware";
import { secureStorage } from "services/utility";

import reducers from "./reducers";

let middlewares = [];

//for promises, since we are using axios for networking
middlewares.push(promise);

// for async operations, network calls
middlewares.push(thunk);

//smart console logging of actions
middlewares.push(logger);

// add freeze dev middleware
// this prevents state from being mutated anywhere in the app during dev
if (process.env.NODE_ENV !== "production") {
    middlewares.push(freeze);
}

// saves the current state of the application to localstorage for preventing state to be lost
function saveStateTOLocalStorage(state) {
    const serilizedState = JSON.stringify(state)
    secureStorage.setItem('state', serilizedState)
}

function loadFromLocalStorage() {
    const serilizedState = secureStorage.getItem('state');
    // console.log({ serilizedState });
    if (serilizedState === null) return undefined
    return typeof serilizedState === 'object' ? serilizedState : JSON.parse(serilizedState);
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistedState = loadFromLocalStorage();

// create store
const store = createStore(
    reducers,
    persistedState,
    composeEnhancers(
        applyMiddleware(...middlewares)
    ),
);

store.subscribe(() => {
    saveStateTOLocalStorage(store.getState())
});

// export
export default store;
