import React, { Suspense } from "react";
import { Switch } from "react-router-dom";
import { GuardProvider, GuardedRoute } from "react-router-guards";
import { routeGuard, contentGuard } from "./services/guards";
import StepAppLoader from "elements/StepAppLoader";

const SelectSchool = React.lazy(() =>
  import("./components/Onboarding/SelectSchool/SelectSchool")
);
const StudentLogin = React.lazy(() =>
  import("./components/Onboarding/OtpLogin/OtpLogin")
);
const SelectSubject = React.lazy(() =>
  import("./components//LearningFlow/SelectSubject/SelectSubject")
);
const SelectChapter = React.lazy(() =>
  import("./components/LearningFlow/SelectChapter/SelectChapter")
);
const Island = React.lazy(() =>
  import("./components//LearningFlow/ShowContent/Island")
);
const TopicContent = React.lazy(() =>
  import("./components/LearningFlow/ShowContent/TopicContent")
);
const ReviseContent = React.lazy(() =>
  import("./components/LearningFlow/ShowContent/ReviseContent")
);
const TreasureContent = React.lazy(() =>
  import("./components/LearningFlow/ShowContent/TreasureContent")
);
const Profile = React.lazy(() => import("./components/Profile/Profile"));
const LeaderBoard = React.lazy(() =>
  import("./components/LeaderBoard/LeaderBoard")
);

const Payment = React.lazy(() => import("./components/Payment"));
const Plans = React.lazy(() => import("./components/Plans"));
const CommonRail = React.lazy(() =>
  import("./components/LearningFlow/ShowContent/CommonRails")
);

const LiveClasses = React.lazy(() => import("./components/LiveClasses"));

const Quizzes = React.lazy(() => import("./components/Quizzes"));
const QuizPlay = React.lazy(() =>
  import("./components/Quizzes/components/Quiz/index")
);
const AssessmentPlay = React.lazy(() =>
  import("./components/Quizzes/components/Assessment/index")
);
const challengePlay = React.lazy(() =>
  import("./components/Quizzes/components/Challenge/index")
);
const ShowChallengeQuestion = React.lazy(() =>
  import(
    "./components/Quizzes/components/Challenge/components/ShowChallengeQuestion"
  )
);
const QuizCreatingGame = React.lazy(() =>
  import(
    "./components/Quizzes/components/Challenge/components/QuizCreatingGame"
  )
);
const WaitingLobby = React.lazy(() =>
  import("./components/Quizzes/components/Challenge/components/WaitingLobby")
);

// Stand alone urls
const Chapter = React.lazy(() => import("./components/Chapter"));
const Topic = React.lazy(() => import("./components/Topic"));
const DisplayBookmark = React.lazy(() =>
  import("./components/Bookmark/DisplayBookmark")
);

// Login
const TnLogin = React.lazy(() => import("./components/TamilNadu/Login"));
// Error pages
const ErrorPage = React.lazy(() => import("./components/500"));
const NoContentFound = React.lazy(() =>
  import("./components/LearningFlow/NoContentFound/NoContentFound")
);
const PageNotFound = React.lazy(() =>
  import("./components/NoPageFound/PageNotFound")
);
const PrivacyPolicy = React.lazy(() => import("./components/PrivacyPolicy"));

export default () => {
  return (
    <GuardProvider guards={[routeGuard]} loading={() => <StepAppLoader />}>
      <Suspense fallback={<StepAppLoader />}>
        <Switch>
          {/* ------------------------------------------------- Guest Routes ------------------------------------------------ 
          Changed default route to Student login instead of Select school
          */}

          <GuardedRoute
            path="/"
            exact
            component={TnLogin}
            meta={{ guest: true }}
          />
          <GuardedRoute
            path="/login/:schlId"
            exact
            component={StudentLogin}
            meta={{ guest: true }}
          />
          <GuardedRoute 
            path="/tamil-nadu/login/:schlId" 
            exact 
            component={TnLogin} 
            meta={{ guest: true }} 
          />
          {/* // ------------------------------------------------- Auth Routes ------------------------------------------------- */}
          <GuardedRoute
            path="/selectsubject"
            exact
            component={SelectSubject}
            meta={{ auth: true }}
          />
          <GuardedRoute
            path="/selectchapter/:subjectslug"
            exact
            component={SelectChapter}
            meta={{ auth: true, name: "subject" }}
          />
          <GuardedRoute
            path="/selecttopic/:subjectslug/:chapterslug"
            exact
            component={Island}
            meta={{ auth: true, name: "chapter" }}
            guards={[contentGuard]}
          />
          <GuardedRoute
            path="/topiccontent/:subjectslug/:chapterslug/:topicslug"
            exact
            component={TopicContent}
            meta={{ auth: true, name: "topic" }}
            guards={[contentGuard]}
          />
          <GuardedRoute
            path="/displayBookmark/:subjectslug/:chapterslug/:topicslug"
            exact
            component={DisplayBookmark}
            meta={{ auth: true, name: "topic" }}
          />
          <GuardedRoute
            path="/revise/:subjectslug/:chapterslug"
            exact
            component={ReviseContent}
            meta={{ auth: true }}
            guards={[contentGuard]}
          />
          <GuardedRoute
            path="/treasurecontent/:subjectslug/:chapterslug"
            exact
            component={TreasureContent}
            meta={{ auth: true }}
            guards={[contentGuard]}
          />
          <GuardedRoute
            path="/plans"
            exact
            component={Plans}
            meta={{ auth: true }}
          />
          <GuardedRoute
            path="/payment/:planId"
            exact
            component={Payment}
            meta={{ auth: true }}
          />
          <GuardedRoute
            path="/profile"
            exact
            component={Profile}
            meta={{ auth: true }}
          />
          <GuardedRoute
            path="/leaderboard"
            exact
            component={LeaderBoard}
            meta={{ auth: true }}
          />
          <GuardedRoute
            path="/live-classes"
            exact
            component={LiveClasses}
            meta={{ auth: true }}
          />
          <GuardedRoute
            path="/quizzes"
            exact
            component={Quizzes}
            meta={{ auth: true }}
          />
          <GuardedRoute
            path="/quizzes/practicequiz/:quizType/:subject"
            exact
            component={QuizPlay}
            meta={{ auth: true }}
          />
          <GuardedRoute
            path="/quizzes/practicetest/:quizType/:subject/:assessmentId"
            exact
            component={AssessmentPlay}
            meta={{ auth: true }}
          />
          <GuardedRoute
            path="/quizzes/practicetest/:quizType/:subject/"
            exact
            component={AssessmentPlay}
            meta={{ auth: true }}
          />
          <GuardedRoute
            path="/quizzes/challenge/:quizType/:subject"
            exact
            component={challengePlay}
            meta={{ auth: true }}
          />
          <GuardedRoute
            path="/quizzes/challenge/question"
            exact
            component={ShowChallengeQuestion}
            meta={{ auth: true }}
          />
          <GuardedRoute
            path="/quizzes/showPlayers/:roomcode"
            exact
            component={QuizCreatingGame}
            meta={{ auth: true }}
          />
          <GuardedRoute
            path="/quizzes/WaitingLobby/:roomcode"
            exact
            component={WaitingLobby}
            meta={{ auth: true }}
          />

          <GuardedRoute
            path="/nocontent"
            exact
            component={NoContentFound}
            meta={{ auth: true }}
          />
          {/*------------------------------------------------- Public Routes ------------------------------------------------ */}
          <GuardedRoute
            path="/course/chapter/:slug"
            exact
            component={Chapter}
            meta={{ public: true }}
          />
          <GuardedRoute
            path="/course/topic/:slug"
            exact
            component={Topic}
            meta={{ public: true }}
          />

          <GuardedRoute
            path="/pagenotfound"
            exact
            component={PageNotFound}
            meta={{ public: true }}
          />
          <GuardedRoute
            path="/displayLink"
            exact
            component={CommonRail}
            meta={{ public: true }}
          />
          <GuardedRoute
            path="/500"
            exact
            component={ErrorPage}
            meta={{ public: true }}
          />
          <GuardedRoute
            path="/privacypolicy"
            exact
            component={PrivacyPolicy}
            meta={{ public: true }}
          />
          {/*------------------------------------------------- Default Route ------------------------------------------------ 
          Changed default route to Student login instead of Select school
          */}
          <GuardedRoute path="*" component={StudentLogin} />
        </Switch>
      </Suspense>
    </GuardProvider>
  );
};
