import  CryptoJS  from "crypto-js";
import SecureStorage from "secure-web-storage";
import config from "config";
 
// https://www.npmjs.com/package/secure-web-storage
export const secureStorage = new SecureStorage(localStorage, {
    hash: function hash(key) {
        return CryptoJS.SHA256(key, config.secret).toString();
    },
    encrypt: function encrypt(data) {
        return CryptoJS.AES.encrypt(data, config.secret).toString();
    },
    decrypt: function decrypt(data) {
        return CryptoJS.AES.decrypt(data, config.secret).toString(CryptoJS.enc.Utf8);
    }
});

export default secureStorage;
