import React from "react";
import SpinnerGif from "assets/new_design/images/Preloader_11.gif";
import Logo from "assets/new_design/images/logo.png";
import QuizLoader from "assets/new_design/images/Loader-data-fecthing.gif";

function StepAppLoader({ show = true, loaderType }) {
  if (!show) return null;

  ///////////// code for adding loaders according to pages //////////////////////

  // if(loaderType === "island")
  // return (
  //     <div className="row d-flex justify-content-center pb-4 mb-4">
  //         <img
  //             className="text-center"
  //             src={QuizLoader}
  //             width="200px"
  //             height="200px"
  //             alt='Stepapp logo'
  //         />
  //     </div>
  // )
  // if(loaderType === "topicContent")
  // return (
  //     <div className="row d-flex justify-content-center pb-4 mb-4">
  //         <img
  //             className="text-center"
  //             src={QuizLoader}
  //             width="200px"
  //             height="200px"
  //             alt='Stepapp logo'
  //         />
  //     </div>
  // )

  ///////////// code for adding loaders according to pages //////////////////////

  return (
    <div className="row no-gutters d-flex justify-content-center pb-4 mb-4">
      {/* <img
        className="text-center"
        src={SpinnerGif}
        width="200px"
        height="200px"
        alt="Stepapp logo"
      /> */}
      <section className="m-auto text-center loader-position">
        <div className="row">
          <div className="col-md-12 my-4">
            <img src={Logo} width="200px" height="auto" />{" "}
          </div>
          <div className="col-md-12 text-center m-auto">
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              width="100px"
              height="100px"
              viewBox="0 0 80 50"
              style={{ textAlign: "center", margin: "auto" }}
            >
              <circle cx={10} cy={10} r={10} fill="#f16b24">
                <animate
                  attributeName="cx"
                  from={10}
                  to={40}
                  dur="0.5s"
                  calcMode="spline"
                  keySplines="0.42 0 0.58 1"
                  keyTimes="0;1"
                  repeatCount="indefinite"
                />
              </circle>
              <circle cx={10} cy={10} r={0} fill="#f16b24">
                <animate
                  attributeName="r"
                  from={0}
                  to={10}
                  dur="0.5s"
                  calcMode="spline"
                  keySplines="0.42 0 0.58 1"
                  keyTimes="0;1"
                  repeatCount="indefinite"
                />
              </circle>
              <circle cx={40} cy={10} r={10} fill="#f16b24">
                <animate
                  attributeName="cx"
                  from={40}
                  to={70}
                  dur="0.5s"
                  calcMode="spline"
                  keySplines="0.42 0 0.58 1"
                  keyTimes="0;1"
                  repeatCount="indefinite"
                />
              </circle>
              <circle cx={70} cy={10} r={10} fill="#e4ac13">
                <animate
                  attributeName="r"
                  from={10}
                  to={0}
                  dur="0.5s"
                  calcMode="spline"
                  keySplines="0.42 0 0.58 1"
                  keyTimes="0;1"
                  repeatCount="indefinite"
                />
              </circle>
            </svg>
          </div>
        </div>
      </section>
    </div>
  );
}

export default StepAppLoader;
